<template>
  <div class="secondflow">
    <div style="position: fixed; left: 0; top: 0; width: 100%; z-index: 10">
      <headers :title="title"></headers>
    </div>
    <!-- list -->
    <div class="flex_column mt50 pb50" :style="{ opacity: istrue ? '1' : '0' }">
      <van-pull-refresh
        v-model="isLoading"
        @refresh="onRefresh"
        pulling-text="Loading..."
        loosing-text="Loading..."
        loading-text="Loading..."
      >
        <div class="container-water-fall case">
          　　　　
          <waterfall
            :col="col"
            :gutterWidth="20"
            :data="List"
            @loadmore="loadmore"
            :lazyDistance="200"
          >
            <template>
              <div
                class="pb20 bbe9s"
                v-for="(item, index) in List"
                :key="index"
                :id="'item' + index"
              >
                <div class="flex_between flex_items p10">
                  <div class="flex_start flex_items">
                    <van-image
                      v-if="item.headerUrl != null"
                      round
                      width="40"
                      height="40"
                      fit="cover"
                      :src="item.headerUrl"
                      class="mr10"
                    ></van-image>
                    <van-image
                      v-else
                      round
                      width="40"
                      height="40"
                      fit="cover"
                      :src="require('@/images/home/mtx.png')"
                      class="mr10"
                    ></van-image>
                    <div class="flex_column">
                      <p class="c0 f15 bold">{{ item.nickName }}</p>
                      <p class="f12" style="color: #8e8e8e">15 min ago</p>
                    </div>
                  </div>
                  <div
                    v-if="!item.follow"
                    class="ptb5 plr10 f14"
                    style="
                      border-radius: 4px;
                      background: #ff7900;
                      color: white;
                    "
                    @click="follow(index, item.author)"
                  >
                    Follow
                  </div>
                </div>
                <!-- img -->
                <!-- @click="workdetail(item.author, item.permlink)" -->
                <imgstype :imgs="item.images"></imgstype>
                <div
                  class="flex_between flex_items p10"
                  @click="workdetail(item.author, item.permlink)"
                >
                  <div class="flex_start flex_items">
                    <van-image
                      v-if="item.like"
                      fit="contain"
                      :src="require('@/images/home/zan1.png')"
                      class="mr15"
                      width="24"
                    ></van-image>
                    <van-image
                      v-else
                      fit="contain"
                      :src="require('@/images/home/zan.png')"
                      class="mr15"
                      width="24"
                      @click.stop="zan(index, item.author, item.permlink)"
                    ></van-image>
                    <van-image
                      fit="contain"
                      :src="require('@/images/home/pinlun.png')"
                      class="mr15"
                      width="24"
                      @click="app"
                    ></van-image>
                    <div
                      @click.stop="zhuan(index)"
                      class="mr15"
                      style="height: 24px"
                    >
                      <zhuanshow
                        :isshow="item.zhuanshow"
                        :author="item.author"
                        :permlink="item.permlink"
                        :ismoreindex="ismoreindex"
                        :transmit="item.transmit"
                        @shareto="getshare"
                        @getindex="getindex"
                      ></zhuanshow>
                    </div>
                    <div
                      style="height: 24px"
                      @click.stop="moreshowclisk(index, item)"
                    >
                      <van-image
                        fit="contain"
                        :src="require('@/images/home/more.png')"
                        class="mr15"
                        width="24"
                      ></van-image>
                    </div>
                  </div>
                  <div class="rpix bold f12 plr10">{{ item.totalMoney }}</div>
                </div>
                <!-- likes -->
                <div class="flex_start flex_items plr10">
                  <span class="mr5 f13"
                    ><span class="bold">{{ item.likeNum }}&nbsp;</span
                    ><span v-if="item.likeNum < 2">Like</span>
                    <span v-else>Likes</span>
                  </span>
                  <span class="mr5 f13 bold">·</span>
                  <span class="mr5 f13"
                    ><span class="bold">{{ item.commentNum }}&nbsp;</span
                    ><span v-if="item.commentNum < 2">Comment</span
                    ><span v-else>Comments</span></span
                  >
                </div>
                <p
                  v-if="item.description != null"
                  class="f14 c0 mt10 plr10"
                  @click.stop="workdetail(item.author, item.permlink)"
                >
                  {{ item.description }}
                </p>
                <div
                  v-if="item.primaryTag != null"
                  class="flex_warp flex_items f14 mt5"
                  style="color: #0a82fa"
                >
                  <span class="ml10 mt5">#{{ item.primaryTag }}</span>
                  <span
                    class="ml10 mt5"
                    v-for="item in item.tags"
                    :key="item.id"
                    >#{{ item }}</span
                  >
                </div>
                <!-- pinglun -->
                <div
                  class="plr10 flex_column f14 mt5"
                  @click.stop="workdetail(item.author, item.permlink)"
                >
                  <div v-if="item.repliesNum != 0">
                    <p
                      v-for="(item, index) in item.replies"
                      :key="index"
                      style="color: #666666"
                    >
                      <span class="mr5 bold c0"> {{ item.nickName }} </span
                      >{{ item.body }}
                    </p>
                  </div>

                  <p
                    v-if="item.repliesNum != 0"
                    class="mt10 f12"
                    style="color: #8e8e8e"
                  >
                    View all {{ item.repliesNum }}
                    <span v-if="item.repliesNum < 2">comment</span
                    ><span v-else>comments</span>
                  </p>
                  <div
                    v-if="item.hotState == 1 || item.nftStatus == 1"
                    class="mt10 flex_start flex_items"
                  >
                    <div
                      v-if="item.hotState == 1"
                      class="fire plr5 flex_start flex_items mr10"
                    >
                      <van-image
                        fit="contain"
                        :src="require('@/images/home/fire.png')"
                        class="mr5"
                        width="14"
                      ></van-image>
                      <span class="f12 c0">Trending</span>
                    </div>
                    <div
                      v-if="item.nftStatus == 2"
                      class="fire plr5 flex_center flex_items mr10"
                    >
                      <van-image
                        fit="contain"
                        :src="require('@/images/home/nft2.png')"
                        class="mr5"
                        width="20"
                      ></van-image>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </waterfall>
        </div>
      </van-pull-refresh>
    </div>
    <!-- more -->
    <van-popup
      v-model="ismoreshow"
      class="p10"
      style="border-radius: 4px; width: 60vw"
    >
      <div
        style="width: 100%"
        class="ptb10 f14 c0"
        @click="follow(ismoreindex, ismoreauthor)"
      >
        {{ isfollw }}
      </div>
      <div
        style="width: 100%"
        class="ptb10 f14 c0"
        @click="collection(ismoreindex, ismoreauthor, ismorepermlink)"
      >
        {{ iscollection }}
      </div>
      <div style="width: 100%" class="ptb10 f14 c0" @click="report">
        Report this Post
      </div>
    </van-popup>
    <!-- isreport -->
    <van-popup
      v-model="isreport"
      class="p10"
      style="border-radius: 4px; width: 60vw"
    >
      <div
        style="width: 100%"
        class="ptb10 f14 c0"
        v-for="item in resons"
        :key="item.id"
        @click="reportsub(item.id)"
      >
        {{ item.title }}
      </div>
    </van-popup>
    <!-- shareto -->
    <transition>
      <div
        v-show="isshow.shareto"
        class="flex_column flex_items bgwhite"
        style="
          width: 94vw;
          position: fixed;
          left: 0;
          bottom: 0;
          z-index: 12;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          padding: 20px 3vw;
        "
      >
        <div class="flex_around flex_items bbe9s pb20" style="width: 90%">
          <ShareNetwork
            network="facebook"
            :url="
              'https://pixie.mobi/en/share?permlink=' +
              isshow.permlink +
              '&author=' +
              isshow.author
            "
            title="In Pixie, this post has already earned 1.5121 PIX (cryptocurrency). Join Pixie now! Early birds will earn more PIX rewards!"
            description=""
            quote=""
            hashtags="vuejs,vite"
          >
            <div class="flex_column flex_items mr15">
              <van-image
                fit="contain"
                :src="require('@/images/home/face.png')"
                class=""
                width="44"
              ></van-image>
              <span class="f14 c0 mt10">Fcebook</span>
            </div>
          </ShareNetwork>
          <ShareNetwork
            network="Twitter"
            :url="
              'https://pixie.mobi/en/share?permlink=' +
              isshow.permlink +
              '&author=' +
              isshow.author
            "
            title="In Pixie, this post has already earned 1.5121 PIX (cryptocurrency). Join Pixie now! Early birds will earn more PIX rewards!"
            description=""
            quote=""
            hashtags="vuejs,vite"
          >
            <div class="flex_column flex_items mr15">
              <van-image
                fit="contain"
                :src="require('@/images/home/twi.png')"
                class=""
                width="44"
              ></van-image>
              <span class="f14 c0 mt10">Twitter</span>
            </div>
          </ShareNetwork>
        </div>
        <div class="ptb10 mt20 f-center f14 c0" @click="isshow.shareto = false">
          Cancle
        </div>
      </div>
    </transition>
    <van-overlay :show="isshow.shareto" @click="isshow.shareto = false" />
  </div>
</template>
<script>
import { Toast } from "vant";
import headers from "@/components/header.vue";
import imgstype from "@/components/imgstype.vue";
import zhuanshow from "@/components/zhuanshow.vue";
export default {
  data() {
    return {
      title: "",
      List: [],
      zhis: {},
      url: "",
      col: 1,
      istrue: false,
      isLoading: false,
      morepopshow: false,
      ismoreshow: false,
      isreport: false,
      ismoreindex: 0,
      ismoreauthor: "",
      ismorepermlink: "",
      isfollw: "Follow",
      iscollection: "Save to Collection",
      isshow: {
        shareto: false,
        author: "",
        permlink: "",
      },
      resons: [
        { id: 0, title: "Spam" },
        { id: 1, title: "Pornographic" },
        { id: 2, title: "Copyright Infringement" },
        { id: 3, title: "Other Reasons" },
      ],
      index: 0,
    };
  },
  components: {
    headers,
    imgstype,
    zhuanshow,
  },
  activated() {
    if (!this.$route.meta.isback) {
      this.istrue = false;
      this.title = this.$route.params.title;
      this.url = this.$route.params.url;
      this.zhis = this.$route.params.zhis;
      this.List = this.$route.params.list;
      setTimeout(() => {
        this.$nextTick(() => {
          document
            .getElementById("item" + this.$route.params.index)
            .scrollIntoView();
          this.istrue = true;
        });
      }, 600);
    }
  },
  methods: {
    getData() {
      if (!this.isLoading) {
        Toast.loading({
          message: "Loading...",
          forbidClick: true,
        });
      }
      this.util.get(this.url, this.zhis).then((res) => {
        console.log(res);
        if (res.code == "000") {
          if (this.zhis.isRefresh == 1) {
            this.List = res.data.list;
          } else {
            res.data.list.forEach((item) => {
              this.List.push(item);
            });
          }
          this.List.forEach((item) => {
            item.zhuanshow = false;
          });
        } else {
          Toast(res.msg);
        }
        this.isLoading = false;
      });
    },
    loadmore() {
      this.zhis.currentPage += 1;
      this.zhis.isRefresh = 0;
      this.getData();
    },
    onRefresh() {
      this.zhis.currentPage = 1;
      this.zhis.isRefresh = 1;
      this.getData();
    },
    zan(index, author, permlink) {
      this.util
        .zan(this.HOST + "/likes", {
          author: author,
          permlink: permlink,
          appVersion: "1.0.5",
          country: "cn",
          deviceName: "iPhone12",
          language: "en",
          platform: "2",
          timestamp: Date.parse(new Date()),
          timezone: "Asia/Shanghai",
          version: "2",
          voter: localStorage.getItem("account"),
        })
        .then((res) => {
          this.List[index].like = true;
          this.List[index].likeNum += 1;
        });
    },
    follow(index, author) {
      Toast.loading({
        message: "Loading...",
        forbidClick: true,
      });
      if (this.isfollw == "Unfollow") {
        this.unfollow(index, author);
        return false;
      }
      this.util
        .follow(this.HOST + "/follow", {
          following: author,
          appVersion: "1.0.5",
          country: "cn",
          deviceName: "iPhone12",
          language: "en",
          osVersion: "14.7.1",
          platform: "2",
          timestamp: Date.parse(new Date()),
          timezone: "Asia/Shanghai",
        })
        .then((res) => {
          if (res.code == "000") {
            Toast("Following");
            this.List[index].follow = true;
            this.isfollw = "Unfollow";
          } else {
            Toast(res.msg);
          }
          this.ismoreshow = false;
        });
    },
    unfollow(index, author) {
      this.util
        .follow(this.HOST + "/unfollow", {
          following: author,
          appVersion: "1.0.5",
          country: "cn",
          deviceName: "iPhone12",
          language: "en",
          osVersion: "14.7.1",
          platform: "2",
          timestamp: Date.parse(new Date()),
          timezone: "Asia/Shanghai",
        })
        .then((res) => {
          if (res.code == "000") {
            Toast("Unfollowed");
            this.isfollw = "Follow";
            this.List[index].follow = false;
          } else {
            Toast(res.msg);
          }
          this.ismoreshow = false;
        });
    },
    zhuan(index) {
      this.ismoreindex = index;
      this.List[index].zhuanshow = !this.List[index].zhuanshow;
    },
    moreshowclisk(index, item) {
      if (this.List[index].follow) {
        this.isfollw = "Unfollow";
      } else {
        this.isfollw = "Follow";
      }
      if (this.List[index].collection) {
        this.iscollection = "Remove from Collection";
      } else {
        this.iscollection = "Save to Collection";
      }
      this.ismoreshow = true;
      this.ismoreindex = index;
      this.ismoreauthor = item.author;
      this.ismorepermlink = item.permlink;
    },
    collection(index, author, permlink) {
      Toast.loading({
        message: "Loading...",
        forbidClick: true,
      });
      let types = 1;
      let mes = "Canceled";
      if (this.iscollection == "Save to Collection") {
        types = 0;
        mes = "Saved to Collection";
      }
      this.util
        .follow(this.HOST + "/collection", {
          type: types,
          permlink: permlink,
          author: author,
        })
        .then((res) => {
          if (res.code == "000") {
            Toast(mes);
            if (types == 0) {
              this.List[index].collection = true;
            } else {
              this.List[index].collection = false;
            }
          } else {
            Toast(res.msg);
          }
          this.ismoreshow = false;
        });
    },
    report(index, author, permlink) {
      this.ismoreshow = false;
      this.isreport = true;
    },
    reportsub(id) {
      Toast.loading({
        message: "Loading...",
        forbidClick: true,
      });
      this.util
        .report(this.HOST + "/reporting", {
          permlink: this.ismorepermlink,
          author: this.ismoreauthor,
          type: id,
          account: localStorage.getItem("account"),
          imageKey: this.List[this.ismoreindex].body,
        })
        .then((res) => {
          console.log(res);
          if (res.code == "000") {
            Toast("Thank you for your feedback");
          } else {
            Toast(res.msg);
          }
          this.ismoreshow = false;
          this.isreport = false;
        });
    },
    workdetail(author, permlink) {
      this.$router.push({
        name: "workdetails",
        params: {
          author: author,
          permlink: permlink,
        },
      });
    },
    selectfen(val) {
      console.log(val);
    },
    getshare(val) {
      this.isshow.shareto = val.zt;
      this.isshow.permlink = val.permlink;
      this.isshow.author = val.author;
    },
    getindex(val) {
      this.List[val.ismoreindex].transmit = true;
    },
    app() {
      this.util.app();
    },
  },
  beforeCreate(to, from, next) {
    window.document.body.style.backgroundColor = "#fff";
  },
  beforeDestroy(to, from, next) {
    window.document.body.style.backgroundColor = "";
  },
  beforeRouteLeave(to, from, next) {
    from.meta.isback = true;
    next(); //不可缺少
  },
};
</script>
<style>
.secondflow .rpix {
  background: linear-gradient(85deg, #ffead6 0%, #ffe0c2 100%);
  opacity: 1;
  border-radius: 4px;
  color: #ff5500;
  height: 20px;
  line-height: 20px;
}
.secondflow .fire {
  background: #f5f5f5;
  border-radius: 6px 0px 6px 0px;
  height: 26px;
  line-height: 26px;
}
</style>