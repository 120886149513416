<template>
  <div class="header">
    <div
      class="flex_between flex_items bgwhite p10"
      style="box-shadow: 0 15px 10px -15px #ccc"
    >
      <van-image
        class=""
        width="24"
        fit="contain"
        :src="require('@/images/home/left.png')"
        @click="back"
      ></van-image>
      <span class="c0 f17 bold">{{ title }}</span>
      <div class="flexX_column">
        <van-image
          class=""
          width="43"
          fit="contain"
          :src="require('@/images/home/energy.png')"
        ></van-image>
        <p class="" style="font-size: 8px">
          <span style="color: #ff5500">300</span>/300
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: ["title"],
  created() {},
  methods: {
    back() {
      console.log(123);
      this.$router.back();
    },
  },
};
</script>